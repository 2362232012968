<template>
  <div class="split-layout__item">
    <b-row no-gutters>
      <b-col>
        <label class="title-activities mr-3">
          {{ $t('views.client.details.activities.left-content.title') }}
        </label>
        <d-vs-dropdown-form
          top="220px"
          :hide="hide"
        >
          <template v-slot:dropdownbtn>
            <label class="sub-date-activities">
              {{ $t('views.client.details.activities.left-content.from') }}
              {{ fromDate }}
              <span class="fa fa-caret-down"></span> </label>
          </template>
          <template v-slot:dropdownbody>
            <date-picker
              :lang="lang"
              :inline="true"
              v-model="searchDate"
              class="custom-datepicker"
            />
          </template>
        </d-vs-dropdown-form>
      </b-col>
    </b-row>
    <p class="mt-4">
      <b-row
        :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'mt-3 mb-3': ''"
      >
        <b-col :cols="getCols">
          <b-row no-gutters>
            <b-col
              :cols="getCustomCols"
              :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'text-center' : ''"
            >
              <feather
                size="71"
                :class="getCustomCols === 6 ? 'ml-2' : ''"
                :stroke="BLUE_LIGTHEN"
                type="calendar"
              />
            </b-col>
            <b-col class="mt-2">
              <label
                :class="getCustomCols === 12 ? 'text-center' : 'text-left'"
                class=" number-card-layout col-12"
              >
                {{ totalBookings }}
              </label>
              <label
                :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'text-center' : 'text-left'"
                class="text-card-layout col-12"
              >
                {{ $t('views.client.details.activities.left-content.bookings') }}
              </label>
            </b-col>
          </b-row>
        </b-col>
        <b-col :cols="getCols">
          <b-row no-gutters>
            <b-col
              :cols="getCustomCols"
              :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'text-center' : ''"
            >
              <feather
                size="71"
                :class="getCustomCols === 12 ? 'ml-2' : ''"
                :stroke="ROSE_LIGTHEN"
                type="x-circle"
              />
            </b-col>
            <b-col class="mt-2">
              <label :class="getCustomCols === 12 ? 'text-center' : 'text-left'" class=" number-card-layout col-12">
                {{ totalCancellations }} </label>
              <label
                class="text-card-layout col-12"
                :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'text-center' : 'text-left'"
              >
                {{ $t('views.client.details.activities.left-content.canceled') }}
              </label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </p>
  </div>
</template>
<script>
import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker: () => import ('vue2-datepicker')
  },
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    totalBookings: {
      type: Number,
      default: 0
    },
    totalCancellations: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    ROSE_LIGTHEN: '#FF0101',
    BLUE_LIGTHEN: "#0B2772",
    fromDate: null,
    hide: false,
    searchDate: null,
  }),
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'];
    },
    lang() {
      return this.$i18n.locale;
    },
    getCols() {
      return this.innerWidth < 1050 ? 6 : 6;
    },
    getCustomCols() {
      return this.innerWidth < 1050 ? 12 : 4;
    },
  },
  watch: {
    searchDate: {
      deep: true,
      handler() {
        const searchDate = this.$toTimezoneCustomFormat({date: this.searchDate, format: YEAR_DASH_MONTH_DASH_DAY});
        const toDate = this.$toTimezoneCustomFormat({date: this.$moment.utc(), format: YEAR_DASH_MONTH_DASH_DAY});

        this.$emit('on:date-update', {searchDate: searchDate, toDate: toDate});

        const diff = this.$moment.utc().diff(this.$moment.utc(this.searchDate), 'days');

        if (diff >= 1) {
          this.fromDate = diff + ' ' + this.$t('views.client.details.activities.left-content.days');
        } else if (diff === 0) {
          this.fromDate = this.$t('views.client.details.activities.left-content.today');
        } else {
          this.fromDate = this.$t('views.client.details.activities.left-content.anytime');
        }

        this.hide = !this.hide;
      },
    }
  },
  created() {
    this.fromDate = this.$t('views.client.details.activities.left-content.anytime');
  },
}
</script>
<style scoped>
.title-activities {
  text-align: left;
  font: normal normal 900 21px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.sub-date-activities {
  text-align: left;
  font: normal normal 13px Arial;
  letter-spacing: 0px;
  color: #BBBCC1;
  opacity: 1;
}

.text-light-blue {
  color: #0B2772 !important
}

.text-light-rose {
  color: #FF0101 !important
}

.number-card-layout {
  text-align: left;
  font: normal normal bold 22px Arial;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

/deep/ .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #0B2772;
  border-radius: 3px;
  padding-top: 0px;
}

/deep/ .button:focus {
  outline: unset;
}

.text-card-layout {
  text-align: left;
  font: normal normal 18px Avenir;
  letter-spacing: 0px;
  color: #A4AFB7;
  opacity: 1;
}
</style>
